import setAuthToken from '../utils/setAuthToken';
import jwt_decode from 'jwt-decode';
import axios from 'axios';
import { geterrors } from '../reducers/errorReducer';
import { setCurrentUser, registerUser } from '../reducers/authReducer';
import { Confirm } from 'notiflix/build/notiflix-confirm-aio';
import { Language } from './language';
import { useNavigate } from 'react-router-dom';


export const login = (userData, userType) => dispatch => {
   axios.post(`/api/${userType}/login`, userData)
      .then(res => {
         // Save to LocalStorage
         const { token } = res.data;
         if (token) {
            //Set Token to Localstorage
            localStorage.setItem('jwtToken', token);
            //Set Token to Header
            setAuthToken(token);
            //Decode Token to get user Data
            const decoded = jwt_decode(token);
            //set Current User
            dispatch(setCurrentUser(decoded));
         } else {
            dispatch(registerUser(res.data));
         }
      })
      .catch(err => {
         dispatch(geterrors(err.response.data))
      })
};


export const verifyOTP = (userData, userType) => dispatch => {
   axios.post(`/api/${userType}/verifyotp`, userData)
      .then(res => {
         // Save to LocalStorage
         const { token } = res.data;
         //Set Token to Localstorage
         localStorage.setItem('jwtToken', token);
         //Set Token to Header
         setAuthToken(token);
         //Decode Token to get user Data
         const decoded = jwt_decode(token);
         //set Current User
         dispatch(setCurrentUser(decoded));

      })
      .catch(err => dispatch(geterrors(err.response.data)))
};

//Register User
export const register = (userData, userType) => dispatch => {
   axios.post(`/api/${userType}/register`, userData)
      .then(res => {
         dispatch(registerUser(res.data));
      })
      .catch(err => dispatch(geterrors(err.response.data)))
};

export const verifyEmail = (userData, userType) => dispatch => {

   axios.post(`/api/${userType}/verify`, userData)
      .then(res => {
         // Save to LocalStorage
         const { token } = res.data;
         //Set Token to Localstorage
         localStorage.setItem('jwtToken', token);
         //Set Token to Header
         setAuthToken(token);
         //Decode Token to get user Data
         const decoded = jwt_decode(token);
         //set Current User
         dispatch(setCurrentUser(decoded));
      })
      .catch(err => {
         dispatch(geterrors(err.response.data))
      })
};


//Log out users
export const logoutUser = () => dispatch => {
   var confirmText = localStorage.lang === "ar" ? "تأكيد" : "Confirm"
   var confirmSubText = localStorage.lang === "ar" ? "هل أنت متأكد أنك تريد تسجيل الخروج؟" : "Are you sure you want to log out?"
   var currLanguage = localStorage.lang === "ar" ? "ar" : "en"
   // const navigate = useNavigate();

   Confirm.init({
      rtl: localStorage.lang === "ar"
   })

   Confirm.show(
      confirmText,
      confirmSubText,
      Language[currLanguage].yes,
      Language[currLanguage].no,
      () => {
         // Remove Token from localstorage
         localStorage.removeItem('jwtToken');
         //Remove Auth Header for future reference
         setAuthToken(false);
         //set Current user to {} which will set isAuthenticated to false
         dispatch(setCurrentUser({}));
         dispatch(registerUser({}))
         window.location = "/"
         // navigate('/');

      },
      () => {

      },
      {
      },
   );


}
